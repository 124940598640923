import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from '../../utils/windowDimensions';
import { run } from '../../utils/runScript';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { MdMusicNote } from 'react-icons/md';
import { shuffle } from '../../utils/arrayFunc';
import { Note, NotePitch } from '../../models/pitch';
import { MdWaves } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';

const Crow = () => {
  const [sliderOn, setSliderOn] = useState(false);
  const { width } = useWindowDimensions();
  const [booting, setBooting] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [crowIndex, setCrowIndex] = useState(0);
  const [nodeId, setNodeId] = useState(900);
  const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
  const boot = () => {
    run(`boot();d_crow();`);
    setBooting(true);
  }
  const quit = () => {
    run(`quit();`);
    setBooting(false);
  }
  useEffect(() => {
    if(!booting){return;}
    setTimeout(()=>{
      setCrowIndex(1-crowIndex);
    }, 600+(Math.random()*1200));
    if(Math.random()<0.4) {
      // nodeId, amp, vibratoDepth, sustain
      run(`s_crow(${nodeId}, ${0.5+0.7*Math.random()}, ${1+1.5*Math.random()}, ${0.6+0.2*Math.random()});`);
      if(nodeId<920){
        setNodeId(v => v+1);
      } else {
        setNodeId(900);
      }
    }
    setPlaying(true);
  }, [crowIndex, booting])
  const scCrow = `
  ~~~supercollider
  SynthDef("jungleCrow", { 
    arg out, amp=1,
    sustain=1, vibratoDepth=0;
    var freq, u, env,
    vibratoFreq=70, freqDiffRate=0.1;
    env = EnvGen.ar(
      Env.linen(0.04, 0, 1, 1, 8),
      timeScale: sustain,
      doneAction:2
    );
    freq = 1000 + Rand(0, 10);
    freq = freq * (
        1+(
        (LFPar.ar(vibratoFreq)+1)
        *(vibratoDepth/50)
      )
      );
    freq = freq*(1-freqDiffRate) + EnvGen.ar(
      Env.linen(0,0,1,freq*freqDiffRate,7),
      timeScale:sustain);
    u = 0;
    u = u+(SinOsc.ar(freq*2)*0.15);
    u = u+(SinOsc.ar(freq*1.95)*0.07);
    u = u+(SinOsc.ar(freq*1.5)*0.13);
    u = u+(SinOsc.ar(freq*1.45)*0.09);
    u = u+(SinOsc.ar(freq*1.04)*0.07);
    u = u+(SinOsc.ar(freq*1)*0.09);
    u = u!2;
    Out.ar(out, u*amp);
  }).play;
  ~~~
  `;
  useEffect(() => {
    try{
      new SharedArrayBuffer(1);
      setSharedArrayBufferEnable(true);
    }catch{
      setSharedArrayBufferEnable(false);
    }
  }, [])
  return (
    <div style={{padding: '10px'}}>
      <Grid container>
        <Button variant="outlined" startIcon={<PowerSettingsNewIcon />} onClick={()=>{boot()}} disabled={booting || !sharedArrayBufferEnable} size="medium">
          PLAY
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<PowerOffIcon />} onClick={()=>{quit()}} disabled={!booting || !sharedArrayBufferEnable} size="medium">
          QUIT
        </Button>
      </Grid>
      {/* <div style={{padding: '10px'}}/>
      <Grid container>
        <Button variant="outlined" startIcon={<MdMusicNote />} onClick={()=>{play()}} disabled={!booting || !sharedArrayBufferEnable || playing} size="medium">
          Sound
        </Button>
      </Grid> */}
      <div style={{padding: '10px'}}/>
      <div style={{whiteSpace: 'pre-wrap', width: '90vw', overflowX: 'scroll', overflowWrap: 'break-word', margin: '0 auto' }}>
        <ReactMarkdown children={scCrow}/>
      </div>
      {/* <Box sx={{ maxWidth: 'calc(100% - 100px)', width: '500px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MdMusicNote size={`2em`} color={booting ? 'inherit' : '#bdbdbd'}/>
            </Grid>
            <Grid item xs>
              <Slider value={sineValue} onChange={handleVolumeChange} disabled={!sliderOn}/>
            </Grid>
        </Grid>
      </Box> */}
    </div>
)
}

export default Crow