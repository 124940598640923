import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import { run } from '../utils/runScript';

type ScProps = {
  sharedArrayBufferEnable: boolean;
  setSharedArrayBufferEnable: React.Dispatch<React.SetStateAction<boolean>>;
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
}

const BootButton: React.FC<ScProps> = ({ sharedArrayBufferEnable, setSharedArrayBufferEnable, booting, setBooting }) => {
  const boot = () => {
    run(`boot();d_rain();d_sinewave();d_pinknoise();`);
    setBooting(true);
  }
  const quit = () => {
    run(`quit();`);
    setBooting(false);
  }
  useEffect(() => {
    try{
      new SharedArrayBuffer(1);
      setSharedArrayBufferEnable(true);
    }catch{
      setSharedArrayBufferEnable(false);
    }
  }, [])
  return (
    <>
      <div style={{paddingTop: '5px', paddingBottom: '15px'}} >
        <div>
          <span style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
            {`iPhoneの方はマナーモードを解除すると音が出ます`}
          </span>
        </div>
        <div>
          <span style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
            {`For iPhone users, the sound will come out when the silent mode is deactivated.`}
          </span>
        </div>
      </div>
      <Grid container>
        <Button variant="outlined" startIcon={<PowerSettingsNewIcon />} onClick={()=>{boot()}} disabled={booting || !sharedArrayBufferEnable} size="medium">
          SOUND ON
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<PowerOffIcon />} onClick={()=>{quit()}} disabled={!booting || !sharedArrayBufferEnable} size="medium">
          OFF
        </Button>
      </Grid>
    </>
  )
}

export default BootButton