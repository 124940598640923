import React, { useState, useRef } from 'react'
import Divider from '@material-ui/core/Divider';
import ReactHlsPlayer, { HlsPlayerProps } from 'react-hls-player';


const News: React.FC = () => {
  const playerRef = useRef(null);
  const playerRef2 = useRef(null);
  const playerRef3 = useRef(null);
  const [size, setSize] = useState<undefined | google.maps.Size>(undefined);
  return (
    <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>
      <h1 style={{padding: '3px', fontSize: '12pt', color: '#000'}}>
        News
      </h1>

      <h2 style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        {`Keita Miyano`}
      </h2>

      <div style={{padding: '3px'}}/>
      <div>2023.3.31  Sonic Architecture (インタラクティブ立体音響&トークライブ live)</div>
      <div style={{paddingTop: '10px'}}>
        <div>19:00-19:30 Keita Miyano (立体音響ambientライブ)</div>
        <div>19:30-20:00 Talk Session</div>
        <div>20:00-22:00 Open Mic</div>
      </div>
      <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
        <div><a href='https://goo.gl/maps/ANjfJbHYthJM1vcb7' target="_blank" rel="noopener noreferrer">東中野 Bar & Music Spot ALT_SPEAKER</a></div>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingBottom: '10px'}}>
        <img
          src='/eventlogo/SonicArchitecture.png'
          width='60%'
        />
      </div>
      <Divider/>
      <h2 style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        {`ambient groove`}
      </h2>
      <div style={{padding: '3px'}}/>
      <div>2023.3.26  サニースポット (acoustic live)</div>
      <div style={{paddingTop: '10px'}}>
        <div>17:00 Open</div>
        <div>17:30-18:40 Live</div>
        <div>20:00 Close</div>
      </div>
      <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
        <div><a href='https://goo.gl/maps/SUatoKD9D6dxzrNV9' target="_blank" rel="noopener noreferrer">サニースポット</a></div>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingBottom: '10px'}}>
        {/* <img
          src='/eventlogo/Echoes.jpg'
          width='60%'
        /> */}
      </div>
      <Divider/>
    </div>
  )
}

export default News
