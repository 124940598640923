import {
  P5CanvasInstance,
  SketchProps,
  Sketch
} from "react-p5-wrapper";

type MySketchProps = SketchProps & {
  speed: number;
  pause: boolean;
  clear: boolean;
  rainFall: number;
  underWater: number;
  setBubbleValue: React.Dispatch<React.SetStateAction<number>>;
  setPourWaterValue: React.Dispatch<React.SetStateAction<number>>;
};

const sketch: Sketch<MySketchProps> = (p: P5CanvasInstance<MySketchProps>) => {
  let width: number = p.windowWidth;
  let height: number = window.screen.availHeight;
  let windowHeight: number = p.windowHeight;
  let sizeTras: number = p.min(width, height) / 870;
  let pause: boolean = false;
  let clear: boolean = false;
  // 雨
  let rainFall: number = 0;
  let rainCount: number = 10;
  let maxRainCount: number = 10;
  let rainLengths: number[] = (new Array<number>(maxRainCount).fill(0)).map((d) => {return p.map(Math.random(), 0, 1, height*0.05, height*0.3)});
  let xs: number[] = (new Array<number>(maxRainCount).fill(0)).map((d) => {return width*Math.random()});
  let ys: number[] = (new Array<number>(maxRainCount).fill(0)).map((d) => {return height*Math.random()});
  let speed: number = 1;
  // 泡
  // let awaCount: number = 10;
  // let maxAwaCount: number = 10;
  // let time = 0;
  // let vertexCount = 100;
  // let centerXs: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0, width)});
  // let centerYs: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0, height)});
  // let maxRadiuses: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(p.min(width, height)/30, p.min(width, height)/8);});
  // let awaSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(1.03, 1.1)});
  // let rotateSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0.0007, 0.003)});
  // let shapeSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0.01, 0.05)});
  // let purupuruSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0.1, 0.5)});
  // let trueCircles: number[] = (new Array<number>(maxAwaCount).fill(0.45));
  // let radiuses: number[] = (new Array<number>(maxAwaCount).fill(0));
  // const getTheta = (i: number) => {return 2.*p.PI*i/vertexCount;}
  // const getAwaX = (i: number, radius: number, centerX: number, purupuruSpeed: number, rotateSpeed: number, shapeSpeed: number) => {
  //   let theta = getTheta(i);
  //   return centerX + radius*0.01*(100.+p.map(p.noise(time*purupuruSpeed), 0, 1, 3, 5)*p.cos(p.noise(time*0.02)*10*p.noise(p.cos(theta+time*0.001),p.sin(theta+time*rotateSpeed),time*shapeSpeed)))*p.cos(theta);
  // }
  // const getAwaY = (i: number, radius: number, centerY: number, purupuruSpeed: number, rotateSpeed: number, shapeSpeed: number, trueCircle: number) => {
  //   let theta = getTheta(i);
  //   return centerY + radius*0.01*(100.+p.map(p.noise(time*purupuruSpeed), 0, 1, 3, 5)*p.cos(p.noise(time*0.02)*10*p.noise(p.cos(theta+time*0.001),p.sin(theta+time*rotateSpeed),time*shapeSpeed)))*p.sin(theta)*trueCircle;
  // }
  // 水たまり
  let underWater: number = 0;
  let underWaterY: number = height * 0.8;
  let waterSurfaceY: number = underWaterY;
  let minWaterSurfaceY: number = height * 0;
  let time = 0;
  let vertexCount = p.min(100, width/10);
  let uncorkVertexCount = Math.floor(vertexCount/2);
  let waterSufaceVibrato = p.min(10, width/100);
  let pourWater = false;
  let pouring = 0;
  let setBubbleValue: React.Dispatch<React.SetStateAction<number>>;
  let setPourWaterValue: React.Dispatch<React.SetStateAction<number>>;

  p.setup = () => {
    p.createCanvas(width, height);
    p.fill(0);
    p.strokeWeight(p.min(sizeTras, 1)*0.4);
  };

  p.updateWithProps = (props: any) => {
    if (props.speed) {
      speed = props.speed;
    }
    if (props.pause!==undefined) {
      pause = props.pause;
    }
    if (props.clear!==undefined) {
      clear = props.clear;
    }
    if (props.rainFall!==undefined) {
      rainCount = maxRainCount * props.rainFall;
      rainFall = props.rainFall;
    }
    if (props.underWater!==undefined) {
      // awaCount = maxAwaCount * props.underWater;
      underWater = props.underWater;
    }
    if (props.pourWater!==undefined) {
      pourWater = props.pourWater;
    }
    if (props.setBubbleValue!==undefined) {
      setBubbleValue = props.setBubbleValue;
    }
    if (props.setPourWaterValue!==undefined) {
      setPourWaterValue = props.setPourWaterValue;
    }
  };

  p.draw = () => {
    if (clear) {
      p.clear(255, 255, 255, 255);
      pause = true;
      return;
    }
    if (pause) {return;}
    p.clear(255, 255, 255, 255); // 前に描画したものをクリア

    if (pourWater) {
      if (pouring<1){
        pouring += 0.2;
      } else {
        pouring = 1;
      }
    } else {
      pouring = 0;
    }

    // 水
    const corkWidth = 40;
    p.strokeWeight(p.min(sizeTras, 1)*0.8);
    p.line(0, underWaterY, 25+p.min(width*0.5-25, 500)-corkWidth/2, underWaterY);
    p.line(25+p.min(width*0.5-25, 500)+corkWidth/2, underWaterY, width, underWaterY);

    p.strokeWeight(p.min(sizeTras, 1)*5);
    if (pourWater) {
      p.line(25+p.min(width*0.5-25, 500)-corkWidth/2, underWaterY, 25+p.min(width*0.5-25, 500)-corkWidth/2+corkWidth*p.cos(p.PI/2), underWaterY+corkWidth*p.sin(p.PI/2));
    } else {
      p.line(25+p.min(width*0.5-25, 500)-corkWidth/2, underWaterY, 25+p.min(width*0.5-25, 500)+corkWidth/2, underWaterY);
    }
    
    p.strokeWeight(0);
    // 溜まっていく水
    if (waterSurfaceY<underWaterY-1) {
      p.fill(100, 100, 100, 50);
      p.beginShape();
      let rainEffect = p.map(rainFall, 0, 1, 0.3, 1.1);
      for (let i=0; i<vertexCount; i++) {
        p.vertex(i*width/vertexCount, waterSurfaceY+waterSufaceVibrato*rainEffect*p.noise(i+time*0.1*rainEffect));
      }
      p.vertex(width, underWaterY);
      p.vertex(0, underWaterY);
      p.endShape(p.CLOSE);

      setBubbleValue(rainFall*100);
    } else {
      setBubbleValue(0);
    }
    // 流れていく水
    if (waterSurfaceY<underWaterY && pourWater) {
      p.fill(100, 100, 100, 50);
      p.beginShape();
      let minBottomWaterWidth = 0.5;
      let maxBottomWaterWidth = 0.6;
      let waterLength = 3;
      let th = 100;
      let pourWaterValue = 100;
      if (underWaterY-waterSurfaceY<th) {
        minBottomWaterWidth *= p.max((underWaterY-waterSurfaceY)/th, 0)**2;
        maxBottomWaterWidth *= p.max((underWaterY-waterSurfaceY)/th, 0)**2;
        waterLength *= (1/(0.1+p.max((underWaterY-waterSurfaceY)/th, 0)))**2;
        pourWaterValue *= p.max((underWaterY-waterSurfaceY)/th, 0)**2;
      }

      // 出だし
      minBottomWaterWidth *= p.max(pouring, 0)**2;
      maxBottomWaterWidth *= p.max(pouring, 0)**2;
      waterLength *= (1/(0.1+p.max(pouring, 0)))**2;
      pourWaterValue *= p.max(pouring, 0)**2;

      let bottomWaterWidth = p.map(0.9*p.sin(time*0.06)+0.7*p.cos(time*0.2), -1, 1, minBottomWaterWidth, maxBottomWaterWidth);
      for (let i=0; i<=uncorkVertexCount; i++) {
        p.vertex(25+p.min(width*0.5-25, 500)-corkWidth/2+(i/uncorkVertexCount)*(1-bottomWaterWidth)*corkWidth/2, underWaterY+(height-underWaterY)*((i/uncorkVertexCount)**waterLength));
      }
      for (let i=uncorkVertexCount; i>=0; i--) {
        p.vertex(25+p.min(width*0.5-25, 500)+corkWidth/2-(i/uncorkVertexCount)*(1-bottomWaterWidth)*corkWidth/2, underWaterY+(height-underWaterY)*((i/uncorkVertexCount)**waterLength));
      }
      p.vertex(25+p.min(width*0.5-25, 500)-corkWidth/2, underWaterY);
      p.endShape(p.CLOSE);

      setPourWaterValue(pourWaterValue);
    }

    if (waterSurfaceY>=minWaterSurfaceY) {
      waterSurfaceY -= 0.5 * rainFall;
    }
    if (pourWater) {
      if (waterSurfaceY<=underWaterY) {
        waterSurfaceY += 0.6;
      }
    }

    time++;
    if (time>10000){time=0;}

    p.strokeWeight(p.min(sizeTras, 1)*0.4);
    // 雨の描画
    if (rainCount>maxRainCount){rainCount=maxRainCount;}
    for (let i=0; i<rainCount; i++) {
      let newY;
      if(ys[i]+rainLengths[i]>waterSurfaceY) {
        xs[i] = width*Math.random();
        ys[i]=p.random(-rainLengths[i], 0);
      }

      newY = ys[i] + rainLengths[i];

      p.line(xs[i], ys[i], xs[i], newY);

      ys[i] = ys[i] + 100*speed;
    }

    // 泡の描画
    // for (let i=0; i<awaCount; i++) {
    //   for (let k=0; k<vertexCount; k++){
    //     if (k < vertexCount-1) {
    //       p.line(
    //         getAwaX(k, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
    //         getAwaY(k, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i], trueCircles[i]),
    //         getAwaX(k+1, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
    //         getAwaY(k+1, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i], trueCircles[i])
    //       );
    //     } else {
    //       p.line(
    //         getAwaX(k, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
    //         getAwaY(k, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i], trueCircles[i]),
    //         getAwaX(0, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
    //         getAwaY(0, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i], trueCircles[i])
    //       );
    //     }
    //   }
    //   if(radiuses[i]<maxRadiuses[i]){
    //     if(radiuses[i]<1){
    //       radiuses[i]=1.5;
    //     }else{
    //       radiuses[i]*=awaSpeeds[i];
    //     }
    //   }else{
    //     radiuses[i]=0.;
    //     centerXs[i] = p.random(0, width);
    //     centerYs[i] = p.random(0, height);
    //     maxRadiuses[i] = p.random(p.min(width, height)/30, p.min(width, height)/8);
    //     awaSpeeds[i] = p.random(1.03, 1.1);
    //     rotateSpeeds[i] = p.random(0.0007, 0.003);
    //     shapeSpeeds[i] = p.random(0.01, 0.03);
    //     purupuruSpeeds[i] = p.random(0.001, 0.05);
    //     trueCircles[i] = p.random(0.4, 0.5);
    //   }
    // }
    // time++;
  }
}

export default sketch