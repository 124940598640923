import React, { useState, useEffect } from 'react'
import BootButton from './BootButton';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@mui/material/Slider';
import BubbleSquare from './soundBlock/BubbleSquare';
import RiverNoiseSquare from './soundBlock/RiverNoiseSquare';
import { map } from '../utils/mathFunc';
import Divider from '@material-ui/core/Divider';
import SimpleSine from './soundBlock/SimpleSine';
import SimpleNoise from './soundBlock/SimpleNoise';

export const Live20230331 = () => {
  const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
  const [booting, setBooting] = useState(false);
  const [playButtonEnabled, setPlayButtonEnabled] = useState(false);
  useEffect(() => {
    if (booting) {
      setTimeout(()=>setPlayButtonEnabled(true), 500);
    }
  }, [booting])
  return (
    <>
      <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>
        <BootButton
          sharedArrayBufferEnable={sharedArrayBufferEnable}
          setSharedArrayBufferEnable={setSharedArrayBufferEnable}
          booting={booting}
          setBooting={setBooting}
        />
      </div>
      <div style={{padding: '5px'}}/>
      <div style={{paddingLeft: '21px', paddingRight: '21px',paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        {`ノイズと水の泡の音の組み合わせで川の環境音をつくる`}
      </div>
      <div style={{padding: '10px'}}/>
      <Grid container justifyContent='space-around' alignItems='center' style={{width: '1000px', maxWidth: '100vw'}}>
        <RiverNoiseSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          defaultVolume={50}
        />
      </Grid>
      <div style={{padding: '5px'}}/>
      <Grid container justifyContent='space-around' alignItems='center' style={{width: '1000px', maxWidth: '100vw'}}>
        <BubbleSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          startNodeId={4001}
          defaultSize={[50, 90]}
          defaultVolume={20}
          defaultBubbleCount={80}
          pan={map(Math.random(), 0, 1, 0, -0.8)}
        />
        <BubbleSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          startNodeId={3001}
          defaultSize={[5, 73]}
          defaultVolume={50}
          defaultBubbleCount={60}
          pan={map(Math.random(), 0, 1, 0.0, 0.8)}
        />
      </Grid>
      <div style={{padding: '30px'}}/>
    </>
  )
}
