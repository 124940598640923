import React, { useState, useEffect } from 'react'
import BootButton from './BootButton';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@mui/material/Slider';
import BubbleSquare from './soundBlock/BubbleSquare';
import RiverNoiseSquare from './soundBlock/RiverNoiseSquare';
import { map } from '../utils/mathFunc';
import Divider from '@material-ui/core/Divider';
import SimpleSine from './soundBlock/SimpleSine';
import SimpleNoise from './soundBlock/SimpleNoise';

type ScProps = {
  sharedArrayBufferEnable: boolean;
  setSharedArrayBufferEnable: React.Dispatch<React.SetStateAction<boolean>>;
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
}

const SoundBlocks: React.FC<ScProps> = ({ sharedArrayBufferEnable, setSharedArrayBufferEnable, booting, setBooting }) => {
  const [playButtonEnabled, setPlayButtonEnabled] = useState(false);
  useEffect(() => {
    if (booting) {
      setTimeout(()=>setPlayButtonEnabled(true), 500);
    }
  }, [booting])
  return (
    <>
      <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>
        <BootButton
          sharedArrayBufferEnable={sharedArrayBufferEnable}
          setSharedArrayBufferEnable={setSharedArrayBufferEnable}
          booting={booting}
          setBooting={setBooting}
        />
      </div>

      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`↑↑↑`}
      </div>
      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`音を出すので、まずこちらをタップお願いします`}
      </div>
      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`Please tap here first to play the sound.`}
      </div>

      <div style={{padding: '5px'}}/>
        <Divider/>
      <div style={{padding: '5px'}}/>

      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        {`サイン波 sine wave`}
      </div>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`サイン波の音とは、一つの音程のみしか含んでいないシンプルな音です。そのため純音と呼ばれており、聴力検査などに用いられています。`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`自然界で聞こえる音はもっと複雑な音ばかりですが、このサイン波を少し変化させることで、水の泡・鳥の声などの音をつくれます。`}
      </div>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`A sine wave sound is a simple sound that contains only one pitch and is used in hearing tests.`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`The sounds we hear in nature are much more complex, but by slightly modifying these sine waves, we can create sounds such as water bubbles and birdsong.`}
      </div>

      <SimpleSine
        booting={booting}
        setBooting={setBooting}
        playButtonEnabled={playButtonEnabled}
        startNodeId={5001}
      />

      <div style={{padding: '5px'}}/>
        <Divider/>
      <div style={{padding: '5px'}}/>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        {`ノイズ noise`}
      </div>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`自然界の音はノイズに溢れています。これは様々な色を混ぜると黒になるように、様々な音が混じっているからです。`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`ランダムな波形の音を出すことでノイズが作れますが、強調する音程の範囲を設けることでいろいろな環境音が生み出せます。`}
      </div>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`Sounds in nature are full of noise. This is because it is a mixture of various sounds, just as mixing various colors results in black.`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`Noise can be created by producing sounds with random waveforms, and various environmental sounds can be created by setting a range of pitches to emphasize.`}
      </div>

      <SimpleNoise
        booting={booting}
        setBooting={setBooting}
        playButtonEnabled={playButtonEnabled}
        nodeId={1006}
      />

      <div style={{padding: '5px'}}/>
        <Divider/>
      <div style={{padding: '5px'}}/>
      <div style={{paddingLeft: '21px', paddingRight: '21px',paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        {`音の積み木 sound blocks`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`上で紹介したサイン波・ノイズを積み上げて、川の環境音を作ります`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`Pile up the sine wave and noise introduced above to create the ambient sound of the river.`}
      </div>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`水の泡の音は、短い感覚でたくさん鳴らすことによって水の流れる音になったり、泡を小さくすることで炭酸の泡のような音が作れます。`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '9pt', color: '#1c1e33'}}>
        {`The sound of water bubbles can be made into the sound of flowing water by making many short sensory sounds, or by making the bubbles smaller, a sound like carbonation bubbles can be created.`}
      </div>

      <div style={{padding: '10px'}}/>
      <Grid container justifyContent='space-around' alignItems='center' style={{width: '1000px', maxWidth: '100vw'}}>
        <RiverNoiseSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          defaultVolume={50}
        />
      </Grid>
      <div style={{padding: '5px'}}/>
      <Grid container justifyContent='space-around' alignItems='center' style={{width: '1000px', maxWidth: '100vw'}}>
        <BubbleSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          startNodeId={4001}
          defaultSize={[50, 90]}
          defaultVolume={20}
          defaultBubbleCount={80}
          pan={map(Math.random(), 0, 1, -0.4, -1)}
        />
        <BubbleSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          startNodeId={3001}
          defaultSize={[5, 73]}
          defaultVolume={50}
          defaultBubbleCount={60}
          pan={map(Math.random(), 0, 1, 0.0, 0.8)}
        />
      </Grid>
      <div style={{padding: '30px'}}/>
    </>
  )
}

export default SoundBlocks