import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@mui/material/Slider';
import { map } from '../../utils/mathFunc';
import { run } from '../../utils/runScript';
import { shuffle } from '../../utils/arrayFunc';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Stack from '@mui/material/Stack';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { BiWater } from 'react-icons/bi';
import { GiRoad } from 'react-icons/gi';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import Button from '@material-ui/core/Button';

type ScProps = {
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
  playButtonEnabled: boolean;
  nodeId: number;
}

const SimpleNoise: React.FC<ScProps> = ({ booting, setBooting, playButtonEnabled, nodeId }) => {
  const masterVolume = 2.5;
  const minDistance = 10;
  const [isPlaying, setIsPlaying] = useState(false);
  const [amp, setAmp] = useState(0.3);
  const [freqRange, setFreqRange] = useState<number[]>([100, 6000]);

  const handlePlayClick = () => {
    if(!isPlaying) {
      setIsPlaying(true);
      run(`s_pinknoise(${masterVolume*amp}, ${freqRange[0]}, ${freqRange[1]}, ${nodeId})`);
    } else {
      setIsPlaying(false);
      run(`free(${nodeId})`);
    }
  }
  const handleAmpChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setAmp(newValue as number);
  };
  const handleFreqRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (event.type === 'mousedown') {
      return;
    }
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setFreqRange([Math.min(newValue[0], freqRange[1] - minDistance), freqRange[1]]);
    } else {
      setFreqRange([freqRange[0], Math.max(newValue[1], freqRange[0] + minDistance)]);
    }
  };
  const toSimpleNoiseParam = () => {
    setFreqRange([100, 6000]);
  }
  const toRiverNoiseParam = () => {
    setFreqRange([475, 2500]);
  }
  const toRoadNoiseParam = () => {
    setFreqRange([100, 1000]);
  }

  useEffect(() => {
    if (isPlaying) {
      run(`c_pinknoise(${masterVolume*amp}, ${freqRange[0]}, ${freqRange[1]}, ${nodeId})`);
    }
  }, [amp, freqRange])
  return (
    <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
      <Stack spacing={1} style={{width: 342, maxWidth: '50vw'}} direction="row" alignItems="center" justifyContent='flex-start' >
        <div style={{cursor: 'pointer', width: 35, height: 35}} onClick={handlePlayClick}>
          {isPlaying ? (
            <PauseCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
          ) : (
            <PlayCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
          )}
        </div>
        <Slider
          size='small'
          color='primary'
          sx={{width: 'calc(90% - 42px)', color: '#3f51b5', "& .MuiSlider-thumb": {width: '9px', height: '9px'}}}
          onChange={handleAmpChange}
          value={amp}
          min={0.01}
          max={1}
          step={0.01}
          disabled={!playButtonEnabled}
        />
        <VolumeUp />
      </Stack>
      <div style={{padding: '10px'}}/>
      <Grid container>
        <Button variant="outlined" startIcon={<BlurOnIcon />} onClick={toSimpleNoiseParam} size="medium">
          SIMPLE
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<BiWater />} onClick={toRiverNoiseParam} size="medium">
          RIVER
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<GiRoad />} onClick={toRoadNoiseParam} size="medium">
          ROAD
        </Button>
      </Grid>
      <div style={{padding: '10px'}}/>
      <Stack style={{width: 342, maxWidth: '50vw'}} direction="row" alignItems="center" justifyContent='flex-start' >
        <div style={{whiteSpace: 'pre-wrap', width: '120px'}}>
          {`音程範囲\nfreq range`}
        </div>
        <Slider
          size='small'
          color='primary'
          sx={{width: '100%', color: '#3f51b5', "& .MuiSlider-thumb": {width: '9px', height: '9px'}}}
          onChange={handleFreqRangeChange}
          value={freqRange}
          min={100}
          max={6000}
          step={10}
          disabled={!playButtonEnabled}
          valueLabelDisplay="on"
        />
      </Stack>
      <div style={{padding: '10px'}}/>
    </div>
  )
}

export default SimpleNoise
