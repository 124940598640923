import React, { useState, useEffect } from 'react'
import { useWindowDimensions } from '../utils/windowDimensions';
import { MdOutlineBubbleChart } from 'react-icons/md';
import { BsCloudRainHeavy } from 'react-icons/bs';
import ReactMarkdown from 'react-markdown';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import BootButton from './BootButton';

type ScProps = {
  sharedArrayBufferEnable: boolean;
  setSharedArrayBufferEnable: React.Dispatch<React.SetStateAction<boolean>>;
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
  foresting: boolean;
  setForesting: React.Dispatch<React.SetStateAction<boolean>>;
  raining: boolean;
  setRaining: React.Dispatch<React.SetStateAction<boolean>>;
  rainValue: number;
  setRainValue: React.Dispatch<React.SetStateAction<number>>;
  forestValue: number;
  setForestValue: React.Dispatch<React.SetStateAction<number>>;
  bubbleValue: number;
  setBubbleValue: React.Dispatch<React.SetStateAction<number>>;
  bubbleDirection: boolean;
  setBubbleDirection: React.Dispatch<React.SetStateAction<boolean>>;
  pourWater: boolean;
  setPourWater: React.Dispatch<React.SetStateAction<boolean>>;
  pourWaterValue: number;
}

const Home: React.FC<ScProps> = ({ sharedArrayBufferEnable, setSharedArrayBufferEnable, booting, setBooting, foresting, setForesting, raining, setRaining, rainValue, setRainValue, forestValue, setForestValue, bubbleValue, setBubbleValue, bubbleDirection, setBubbleDirection, pourWater, setPourWater, pourWaterValue }) => {
  const [sliderOn, setSliderOn] = useState(false);
  const { width } = useWindowDimensions();
  let height: number = window.screen.availHeight;
  const handleForestChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    setForestValue(newValue as number);
  };
  const handleRainChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    setRainValue(newValue as number);
  };
  const handleBubbleChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    setBubbleValue(newValue as number);
  };
  const scForest = `
  ~~~supercollider
  SynthDef("sunsetForest", {
    arg amp=2.5;
    var a, out=0;
    a=PinkNoise.ar(1!2);
    75.do{
      a=BBandStop.ar(a,LFNoise1.kr(0.01.rand).range(10,2500),Rand(0.9,1))
    };
    a=LPF.ar(a,7800);
    a=HPF.ar(a,1500);
    Out.ar(out, amp * a * 5);
  }, [5]).play;
  ~~~`;
  const scRain = `
  ~~~supercollider
  SynthDef("rain", {
    arg amp=1;
    var sig, out=0;
    sig = { TRand.ar(-1, 1, Dust.ar(25000)) };
    sig = BPF.ar(sig, 100, 0.5);
    sig = HPF.ar(sig, 6000);
    sig = LPF.ar(sig, 5000);
    sig = sig*10*amp;
    sig = sig ! 2;
    Out.ar(out, sig);
  }, [5]).add;

  r = Synth("rain", ["amp", 0]);
  r.set("amp", ${rainValue/100});
  ~~~`;
  const scBubble = `
  ~~~supercollider
  SynthDef("bubble", {
    arg amp=${bubbleValue/100}, pan=Rand(-1.0, 1.0), lpf=5000;
    var sound, accelerate, freq, sustain, out=0;
    r = 1 - (Rand(0.0, 1.0) * Rand(0.0, 1.0) * Rand(0.0, 1.0));
    s = Rand(0.0, 1.0) * Rand(0.0, 1.0) * Rand(0.0, 1.0);
    s = s*s;
    freq = (4698 * (1-r)) + (r * 82);
    sustain = 0.0002 + (r * 0.06);
    accelerate = r * 10;
    amp = amp * (1-(r*r*r)) * s;
    sound = LPF.ar(SinOsc.ar(freq).dup, lpf) * EnvGen.ar(Env.linen(0.01, 0, 0.6, 1, -3), timeScale:sustain, doneAction:2);
    sound=NumChannels.ar([sound*(1-pan)/2, sound*(1+pan)/2], 2);
    Out.ar(out, sound*amp);
  }).play;
  ~~~`;
  const scSimpleSineWave = `
  ~~~supercollider
  SynthDef("simpleSineWave", {
    arg amp=1, sustain=1, pan=0, freq=110, accelerate=0, lpf=5000, out=0;
    var sound, env;
    freq = freq * Line.kr(1, 1+accelerate, 0.5);
    sound = SinOsc.ar(freq).dup!2 * 0.5;
    sound = LPF.ar(sound, lpf);
    sound=NumChannels.ar([sound*(1-pan)/2, sound*(1+pan)/2], 2);
    env = EnvGen.ar(Env.linen(0.01, 0, 0.6, 1, -3), timeScale:sustain, doneAction:2);
    Out.ar(out, sound*env*amp);
  }).add;
  ~~~`;
  const scDripping = `
  ~~~supercollider
  f = {arg value, fromMin, fromMax, toMin, toMax;((value - fromMin) * (toMax - toMin) / (fromMax - fromMin)) + toMin;};
  Routine({
    loop {
      var rand = rrand(0.0, 1.0);
      var sustain = f.value(rand, 0, 1, 0.008, 0.07) * 1.1;
      var freq = f.value(sqrt(rand), 0, 1, 391.995*16, 311.127*1.6);
      var accelerate = f.value(rand, 0, 1, 2, 5);
      var pan = f.value(rrand(0.0, 1.0), 0, 1, -1, 1);
      var amp = f.value(rand*rand, 0, 1, 0.06, 1);
      amp = amp * f.value(rrand(0.0, 1.0)*rrand(0.0, 1.0), 0, 1, 0, 1);
      ${bubbleValue===0 ? "// " : ""}Synth("simpleSineWave", ["amp", amp*0.1, "freq", freq, "sustain", sustain, "pan", pan, "accelerate", accelerate, "lpf", 5000]);
      ${(2*(7.5 + 200*((1 - bubbleValue/100)**3)) / 1000).toFixed(3)}.yield;
    }
  }).play;
  ~~~`
  const scPourWater = `
  ~~~supercollider
  f = {arg value, fromMin, fromMax, toMin, toMax;((value - fromMin) * (toMax - toMin) / (fromMax - fromMin)) + toMin;};
  Routine({
    loop {
      var rand = rrand(0.0, 1.0);
      var sustain = f.value(rand, 0, 1, 0.008, 0.07) * 1.2;
      var freq = f.value(sqrt(rand), 0, 1, 391.995*15, 311.127*0.7);
      var accelerate = f.value(rand, 0, 1, 2, 4.5);
      var amp = f.value(rand*rand, 0, 1, 0.1, 1);
      amp = amp * f.value(rrand(0.0, 1.0)*rrand(0.0, 1.0), 0, 1, 0, 1);
      ${!pourWater ? "// " : ""}Synth("simpleSineWave", ["amp", amp*0.1, "freq", freq, "sustain", sustain, "pan", 0, "accelerate", accelerate, "lpf", 5000]);
      ${((10+10000*((1 - pourWaterValue/100)**5))/1000).toFixed(3)}.yield;
    }
  }).play;
  ~~~`
  useEffect(() => {
    setTimeout(()=>setSliderOn(booting), 300);
  }, [booting])
  return (
    <>
      <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>
        <BootButton
          sharedArrayBufferEnable={sharedArrayBufferEnable}
          setSharedArrayBufferEnable={setSharedArrayBufferEnable}
          booting={booting}
          setBooting={setBooting}
        />
        <div style={{padding: '10px'}}/>
        <Box sx={{ width: '1000px', maxWidth: '100%' }}>
          <Grid container spacing={2} alignItems="center">
          <Grid item>
              <BsCloudRainHeavy size={`1.5em`} color={booting ? 'inherit' : '#bdbdbd'}/>
            </Grid>
            <Grid item xs>
              <Slider value={rainValue} onChange={handleRainChange} disabled={!sliderOn}/>
            </Grid>
            <Grid item>
              <MdOutlineBubbleChart size={`1.5em`} color={booting ? 'inherit' : '#bdbdbd'}/>
            </Grid>
            <Grid item xs>
              <Slider value={bubbleValue} onChange={handleBubbleChange} disabled={true}/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: '1000px', maxWidth: '100%'}}>
          <Grid container justifyContent='center'>
            <Grid item xs={6}>
              <div style={{whiteSpace: 'pre-wrap', width: '90%', overflowX: 'scroll', margin: '0 auto' }}>
                <ReactMarkdown children={scRain}/>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{whiteSpace: 'pre-wrap', width: '90%', overflowX: 'scroll', margin: '0 auto' }}>
                <ReactMarkdown children={scDripping}/>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* <div style={{position: 'relative'}}>
        <Ripples color="rgba(150, 150, 150, .1)" during={2500}>
          <button style={{height: '69.375vw', width: '100vw', opacity: '0'}}>
          </button>
        </Ripples>
      </div> */}
      <Grid style={{position: 'absolute', height: height*0.2, top: height*0.8, width: Math.min(width, 1000+50)}} container justifyContent='center' alignItems='center'>
        <span style={{color: "#ccc", fontSize: '14pt'}}>{pourWater ? 'Cork' : 'Uncork'}</span>
        <Box borderRadius={16} border="dashed 1px #CCCCCC" width="95%" height="95%" position="absolute" justifyContent='center' onClick={()=>{setPourWater(v=>!v)}}/>
      </Grid>
    </>
  )
}

export default Home
