import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@mui/material/Slider';
import { map } from '../../utils/mathFunc';
import { run } from '../../utils/runScript';
import { shuffle } from '../../utils/arrayFunc';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Stack from '@mui/material/Stack';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { MdOutlineWaterDrop } from 'react-icons/md';
import { GiHummingbird } from 'react-icons/gi';
import { TbWaveSine } from 'react-icons/tb';
import Button from '@material-ui/core/Button';

type ScProps = {
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
  playButtonEnabled: boolean;
  startNodeId: number;
}

const SimpleSine: React.FC<ScProps> = ({ booting, setBooting, playButtonEnabled, startNodeId }) => {
  const [amp, setAmp] = useState(0.25);
  const [sustain, setSustain] = useState(2);
  const [freq, setFreq] = useState(880);
  const [accelerate, setAccelerate] = useState(0);
  const [nodeId, setNodeId] = useState(startNodeId);
  const lpf = 10000;
  const pan = 0;

  const playSine = (sustain_: number, freq_: number, accelerate_: number) => {
    run(`s_sinewave(${amp}, ${sustain_}, ${pan}, ${freq_}, ${accelerate_}, ${lpf}, ${nodeId})`);
    if(nodeId<=startNodeId+100) {
      setNodeId(v=>v+1);
    }else {
      setNodeId(startNodeId)
    }
  }
  const handlePlayClick = () => {
    playSine(sustain, freq, accelerate);
  }
  const handleAmpChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setAmp(newValue as number);
  };
  const handleSustainChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setSustain(newValue as number);
  };
  const handleFreqChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setFreq(newValue as number);
  };
  const handleAccelerateChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setAccelerate(newValue as number);
  };
  const toSimpleSineParam = () => {
    let sustain_ = map(Math.random(), 0, 1, 0.5, 3);
    sustain_ = Math.floor(sustain_*10) / 10;
    let freq_ = shuffle([261.626*2, 293.665*2, 329.628*2, 349.228, 391.995, 440, 493.883])[0];
    freq_ *= shuffle([1, 2])[0];
    freq_ = Math.floor(freq_);
    setSustain(sustain_);
    setFreq(freq_);
    setAccelerate(0);
  }
  const toWaterParam = () => {
    const bubbleSizeMin = 5;
    const bubbleSizeMax = 73;
    let rand = Math.random();
    let sustain_ = map(rand, 0, 1, 1/bubbleSizeMax, Math.min(1/bubbleSizeMin, 0.08)) * 1.1;
    let freq_ = map(Math.sqrt(rand), 0, 1, bubbleSizeMax**2, bubbleSizeMin**2);
    let accelerate_ = map(rand, 0, 1, Math.sqrt(304/bubbleSizeMax), Math.sqrt(304/bubbleSizeMin));
    sustain_ = Math.floor(sustain_*100) / 100;
    freq_ = Math.floor(freq_);
    accelerate_ = Math.floor(accelerate_*10) / 10;
    setSustain(sustain_);
    setFreq(freq_);
    setAccelerate(accelerate_);
  }
  const toBirdParam = () => {
    let rand = Math.random();
    let sustain_ = map(rand, 0, 1, 0.05, 0.1);
    sustain_ = Math.floor(sustain_*100) / 100;
    let freq_ = map(rand, 0, 1, 2000, 4000);
    freq_ = Math.floor(freq_);
    let accelerate_ = map(rand, 1, 0, -4, -8);
    accelerate_ = Math.floor(accelerate_*10) / 10;
    setSustain(sustain_);
    setFreq(freq_);
    setAccelerate(accelerate_);
  }
  return (
    <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
      <Stack spacing={1} style={{width: 342, maxWidth: '50vw'}} direction="row" alignItems="center" justifyContent='flex-start' >
        <div style={{cursor: 'pointer', width: 35, height: 35}} onClick={handlePlayClick}>
          <PlayCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
        </div>
        <Slider
          size='small'
          color='primary'
          sx={{width: 'calc(90% - 42px)', color: '#3f51b5', "& .MuiSlider-thumb": {width: '9px', height: '9px'}}}
          onChange={handleAmpChange}
          value={amp}
          min={0.01}
          max={1}
          step={0.01}
          disabled={!playButtonEnabled}
        />
        <VolumeUp />
      </Stack>
      <div style={{padding: '10px'}}/>
      <Grid container>
        <Button variant="outlined" startIcon={<TbWaveSine />} onClick={toSimpleSineParam} size="medium">
          SIMPLE
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<MdOutlineWaterDrop />} onClick={toWaterParam} size="medium">
          WATER
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<GiHummingbird />} onClick={toBirdParam} size="medium">
          BIRD
        </Button>
      </Grid>
      <div style={{padding: '10px'}}/>
      <Stack style={{width: 342, maxWidth: '50vw'}} direction="row" alignItems="center" justifyContent='flex-start' >
        <div style={{whiteSpace: 'pre-wrap', width: '120px'}}>
          {`音の長さ\nsustain`}
        </div>
        <Slider
          size='small'
          color='primary'
          sx={{width: '100%', color: '#3f51b5', "& .MuiSlider-thumb": {width: '9px', height: '9px'}}}
          onChange={handleSustainChange}
          value={sustain}
          min={0.01}
          max={3}
          step={0.01}
          disabled={!playButtonEnabled}
          valueLabelDisplay="on"
        />
      </Stack>
      <div style={{padding: '10px'}}/>
      <Stack style={{width: 342, maxWidth: '50vw'}} direction="row" alignItems="center" justifyContent='flex-start' >
        <div style={{whiteSpace: 'pre-wrap', width: '120px'}}>
          {`音の高さ\nfrequency`}
        </div>
        <Slider
          size='small'
          color='primary'
          sx={{width: '100%', color: '#3f51b5', "& .MuiSlider-thumb": {width: '9px', height: '9px'}}}
          onChange={handleFreqChange}
          value={freq}
          min={100}
          max={4000}
          step={10}
          disabled={!playButtonEnabled}
          valueLabelDisplay="on"
        />
      </Stack>
      <div style={{padding: '10px'}}/>
      <Stack style={{width: 342, maxWidth: '50vw'}} direction="row" alignItems="center" justifyContent='flex-start' >
        <div style={{whiteSpace: 'pre-wrap', width: '120px'}}>
          {`音程変化\naccelerate`}
        </div>
        <Slider
          size='small'
          color='primary'
          sx={{width: '100%', color: '#3f51b5', "& .MuiSlider-thumb": {width: '9px', height: '9px'}}}
          onChange={handleAccelerateChange}
          value={accelerate}
          min={-8}
          max={5}
          step={0.1}
          disabled={!playButtonEnabled}
          valueLabelDisplay="on"
        />
      </Stack>
    </div>
  )
}

export default SimpleSine
