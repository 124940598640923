import React from 'react'
import Grid from '@material-ui/core/Grid';
import { BsGithub, BsInstagram, BsYoutube, BsTwitter } from 'react-icons/bs';
import { GrSoundcloud } from 'react-icons/gr';

const Contact: React.FC = () => {
  return (
    <div style={{padding: '25px'}}>
      <div style={{paddingLeft: '3px', paddingBottom: '20px'}}>
        <Grid container spacing={2} >
          <Grid item>
            <a href='https://soundcloud.com/jtumafnqbjsb' target="_blank" rel="noopener noreferrer">
              <GrSoundcloud size={`1.5em`}/>
            </a>
          </Grid>
          <Grid item>
            <a href='https://github.com/mynkit' target="_blank" rel="noopener noreferrer">
              <BsGithub size={`1.5em`}/>
            </a>
          </Grid>
          <Grid item>
            <a href='https://www.instagram.com/keitamiyano_/' target="_blank" rel="noopener noreferrer">
              <BsInstagram size={`1.5em`}/>
            </a>
          </Grid>
          <Grid item>
            <a href='https://www.youtube.com/channel/UCgL1E7xKm1s_0WoItb-3CHA' target="_blank" rel="noopener noreferrer">
              <BsYoutube size={`1.5em`}/>
            </a>
          </Grid>
          <Grid item>
            <a href='https://twitter.com/keitamiyano_' target="_blank" rel="noopener noreferrer">
              <BsTwitter size={`1.5em`}/>
            </a>
          </Grid>
        </Grid>
      </div>
      <span>
        ご連絡は
        <a href='https://www.instagram.com/keitamiyano_/' target="_blank" rel="noopener noreferrer">Instagram</a>
        か
        <a href='https://twitter.com/keitamiyano_' target="_blank" rel="noopener noreferrer">Twitter</a>
        のDM、またはメールにてお願いします。
      </span>
      <div style={{paddingTop: '10px'}}>
        keita.miyano@async-studio.com
      </div>
    </div>
  )
}

export default Contact