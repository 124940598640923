import React from 'react'
import Divider from '@material-ui/core/Divider';

const Works: React.FC = () => {
  return (
    <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>

      <h1 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#000'}}>
        Projects
      </h1>

      <h2 style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '12pt', color: '#000'}}>
        HOTEL GRAPHY NEZU 10th Anniversary Web Exihibition
      </h2>
      <h3 style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        「Assemble Sound Objects.」
      </h3>

      <a href='https://10thanniversary.hotel-graphy.com/' target='_blank' rel='noopener noreferrer'>
        https://10thanniversary.hotel-graphy.com/
      </a>

      <div style={{padding: '15px'}}/>

      <div style={{whiteSpace: 'pre-wrap'}}>{`2023.4.15-2023.4.16\t10th Anniversary Events`}</div>
      <div style={{whiteSpace: 'pre-wrap'}}>{`2023.4.17-2023.5.15\tAverable Stay (Art Room)`}</div>

      <div style={{padding: '5px'}}/>

      <div style={{width: '600px', maxWidth: '100%', paddingTop: '15px', paddingBottom: '15px'}}>
        <a href='https://10thanniversary.hotel-graphy.com/' target='_blank' rel='noopener noreferrer'>
          <img
            src='/workslogo/hotelgraphy.png'
            width='60%'
          />
        </a>
      </div>

      <Divider/>
      
      <h1 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#000'}}>
        Art
      </h1>
      
      <h2 style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '12pt', color: '#000'}}>
        Digital Albums
      </h2>
      <h3 style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        <span style={{display: 'inline-block'}}>電​子​音​浴 -​中​動​態​の​環​境​音​- Electron Sound Bath</span>
      </h3>
      <div>2023/3/8 release</div>
      <div>1. tense 09:57</div>
      <div>2. aspect 07:12</div>
      <div>
        <a href='https://keitamiyano.bandcamp.com/album/electron-sound-bath' target='_blank' rel='noopener noreferrer'>
          buy album
        </a>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}>
        <a href='https://keitamiyano.bandcamp.com/album/electron-sound-bath' target='_blank' rel='noopener noreferrer'>
          <img
            src='/workslogo/ElectronSoundBath.jpg'
            width='60%'
          />
        </a>
      </div>
      <Divider/>
      <h2 style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '12pt', color: '#000'}}>
        Generative Art
      </h2>
      <h3 style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        <span style={{display: 'inline-block'}}>Generative Art Web Site</span>
      </h3>
      <div>
        <a href='https://generativeart.async-studio.com/' target='_blank' rel='noopener noreferrer'>
          https://generativeart.async-studio.com/
        </a>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}>
        <a href='https://generativeart.async-studio.com/' target='_blank' rel='noopener noreferrer'>
          <img
            src='/workslogo/GenerativeArt.jpg'
            width='60%'
          />
        </a>
      </div>
      <Divider/>
    </div>
  )
}

export default Works