import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from '../utils/windowDimensions';
import { run } from '../utils/runScript';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { MdMusicNote } from 'react-icons/md';
import { shuffle } from '../utils/arrayFunc';
import { Note, NotePitch } from '../models/pitch';
import { MdWaves } from 'react-icons/md';

const Live: React.FC = () => {
    const [sliderOn, setSliderOn] = useState(false);
    const { width } = useWindowDimensions();
    const [booting, setBooting] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
    const [sineValue, setSineValue] = useState(20);
    const freqs: Note[] = [
      'd4', 'bf4', 'c5', 'f5', 'a5'
    ];
    const [freq, setFreq] = useState(NotePitch[freqs[Math.floor( Math.random() * (freqs.length+1) )]]);
    const [tremolo, setTremolo] = useState(0.1);
    const boot = () => {
      run(`boot();d_sine2();`);
      setBooting(true);
    }
    const quit = () => {
      run(`quit();`);
      setBooting(false);
    }
    const play = () => {
      run(`s_sine2(${sineValue*0.01}, ${freq*(1+Math.random()*0.02)});`);
      setPlaying(true);
    }
    const handleVolumeChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setSineValue(newValue as number);
    };
    const handleTremoloChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setTremolo(newValue as number);
    };
    useEffect(() => {
      try{
        new SharedArrayBuffer(1);
        setSharedArrayBufferEnable(true);
      }catch{
        setSharedArrayBufferEnable(false);
      }
    }, [])
    useEffect(() => {
      setTimeout(()=>setSliderOn(booting), 300);
    }, [booting])
    useEffect(() => {
      if (playing) {
        run(`c_sine2(${sineValue*0.01}, ${freq*(1+Math.random()*0.02)}, ${tremolo*0.2});`);
      }
    }, [sineValue, freq, tremolo])
    return (
        <div style={{padding: '10px'}}>
          <h1 style={{marginTop: '10px', marginBottom: '10px'}}>Paint Reactive</h1>
          <div>
            <span style={{fontSize: '13pt'}}>GoogleChromeかSafariで開いてください</span>
          </div>
          <div>
            <span style={{fontSize: '13pt'}}>iPhoneの方はマナーモードを解除してください</span>
          </div>
          <div style={{padding: '10px'}}/>
          <Grid container>
            <Button variant="outlined" startIcon={<PowerSettingsNewIcon />} onClick={()=>{boot()}} disabled={booting || !sharedArrayBufferEnable} size="medium">
              BOOT SOUND SERVER
            </Button>
            <div style={{paddingLeft: '10px'}}/>
            <Button variant="outlined" startIcon={<PowerOffIcon />} onClick={()=>{quit()}} disabled={!booting || !sharedArrayBufferEnable} size="medium">
              QUIT
            </Button>
          </Grid>
          <div style={{padding: '10px'}}/>
          <Grid container>
            <Button variant="outlined" startIcon={<MdMusicNote />} onClick={()=>{play()}} disabled={!booting || !sharedArrayBufferEnable || playing} size="medium">
              Play Sine
            </Button>
          </Grid>
          <div style={{padding: '10px'}}/>
          <Box sx={{ maxWidth: 'calc(100% - 100px)', width: '500px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <MdMusicNote size={`2em`} color={booting ? 'inherit' : '#bdbdbd'}/>
                </Grid>
                <Grid item xs>
                  <Slider value={sineValue} onChange={handleVolumeChange} disabled={!sliderOn}/>
                </Grid>
            </Grid>
          </Box>
          <div style={{padding: '10px'}}/>
          <Box sx={{ maxWidth: 'calc(100% - 100px)', width: '500px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Button variant="outlined" onClick={()=>{setFreq(NotePitch[freqs[0]])}} disabled={!sliderOn}>
                  D4
                </Button>
              </Grid>
              <Grid item xs>
                <Button variant="outlined" onClick={()=>{setFreq(NotePitch[freqs[1]])}} disabled={!sliderOn}>
                  B♭4
                </Button>
              </Grid>
              <Grid item xs>
                <Button variant="outlined" onClick={()=>{setFreq(NotePitch[freqs[2]])}} disabled={!sliderOn}>
                  C5
                </Button>
              </Grid>
              <Grid item xs>
                <Button variant="outlined" onClick={()=>{setFreq(NotePitch[freqs[3]])}} disabled={!sliderOn}>
                  F5
                </Button>
              </Grid>
              <Grid item xs>
                <Button variant="outlined" onClick={()=>{setFreq(NotePitch[freqs[4]])}} disabled={!sliderOn}>
                  A5
                </Button>
              </Grid>
            </Grid>
          </Box>
          <div style={{padding: '10px'}}/>
          <Box sx={{ maxWidth: 'calc(100% - 100px)', width: '500px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <MdWaves size={`2em`} color={booting ? 'inherit' : '#bdbdbd'}/>
                </Grid>
                <Grid item xs>
                  <Slider value={tremolo} onChange={handleTremoloChange} disabled={!sliderOn}/>
                </Grid>
            </Grid>
          </Box>
        </div>
    )
}

export default Live;