import React, { useState, useRef } from 'react'
import Divider from '@material-ui/core/Divider';
import ReactHlsPlayer from 'react-hls-player';

const EventMovies: React.FC = () => {
  const playerRef = useRef(null);
  const playerRef2 = useRef(null);
  const playerRef3 = useRef(null);
  const playerRef4 = useRef(null);
  const playerRef5 = useRef(null);
  const playerRef6 = useRef(null);
  return (
    <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>
      <h1 style={{padding: '3px', fontSize: '12pt', color: '#000'}}>
        Event Movies
      </h1>

      <h2 style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        {`Keita Miyano`}
      </h2>
      <div id='sonic_architecture'>2023.3.31 Sonic Architecture Vol.0</div>
      <div style={{paddingTop: '10px'}}>
        <div><a href ='https://goo.gl/maps/iHLV3sH8A6aqRKYH7' target='_blank' rel='noopener noreferrer'>Bar & Music Spot ALT_SPEAKER</a></div>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px'}}>
        <ReactHlsPlayer
          playerRef={playerRef6}
          src="https://keitamiyano.s3.ap-northeast-1.amazonaws.com/videos/sonicarchitecture/transcoded/video.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div style={{paddingTop: '15px'}}/>
      {/* <Divider/> */}
      <div style={{paddingTop: '15px'}}/>

      <div id='electron_sound_bath'>2023.2.2 - 2023.2.8 初個展「電子音浴 -中動態の環境音-」</div>
      <div style={{paddingTop: '10px'}}>
        <div><a href ='https://goo.gl/maps/1U9ovvp2VQtv7dqa8' target='_blank' rel='noopener noreferrer'>FEI ART MUSEUM YOKOHAMA</a></div>
      </div>
      <div style={{fontWeight: 'bold', paddingTop: '10px', fontSize: '10pt'}}>電子音の森の１日 A Day in the Forest of Electronic Sound</div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px'}}>
        <ReactHlsPlayer
          playerRef={playerRef5}
          src="https://keitamiyano.s3.ap-northeast-1.amazonaws.com/videos/soloexhibition/fei/transcoded/video.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div style={{fontWeight: 'bold', paddingTop: '10px', fontSize: '10pt'}}>Javascript製の鳥たち JS Birds</div>
      <ul style={{fontSize: '10pt', margin: '5px'}}>
        <li><a href='/turtledove' target="_blank" rel="noopener noreferrer">キジバト Turtledove</a></li>
        <li><a href='/higurashi' target="_blank" rel="noopener noreferrer">ヒグラシ Higurashi</a></li>
        <li><a href='/crow' target="_blank" rel="noopener noreferrer">カラス Crow</a></li>
      </ul>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px'}}>
        <ReactHlsPlayer
          playerRef={playerRef4}
          src="https://keitamiyano.s3.ap-northeast-1.amazonaws.com/videos/soloexhibition/fei/js_birds/transcoded/video.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div style={{fontWeight: 'bold', paddingTop: '10px', fontSize: '10pt'}}>音のサイン波</div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px'}}>
        <ReactHlsPlayer
          playerRef={playerRef3}
          src="https://keitamiyano.s3.ap-northeast-1.amazonaws.com/videos/soloexhibition/fei/entrance_sinewave/transcodedvideo.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{paddingTop: '15px'}}/>
      <Divider/>

      <h2 style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
        KageBōsi
      </h2>
      <div>
        2022.12.18 「Paint Reactive」- Drawing Sound Session in NorthVillage - feat. <a href='https://www.tabigeininmone.com/' target='_blank' rel='noopener noreferrer'>藤田モネ</a>
      </div>
      <div style={{paddingTop: '10px'}}>
        <div><a href ='https://goo.gl/maps/jh1AN6ZdJARqqY1Y9' target='_blank' rel='noopener noreferrer'>SHISHAカフェ NORTH VILLAGE 下北沢2号店</a></div>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px'}}>
        <ReactHlsPlayer
          playerRef={playerRef2}
          src="https://keitamiyano.s3.ap-northeast-1.amazonaws.com/videos/paintreactive/transcoded/pr.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div style={{paddingTop: '15px'}}/>
      {/* <Divider/> */}
      <div style={{paddingTop: '15px'}}/>
      <div>
        2022.11.3 TouchDesigner meetup TOKYO 2022.11
      </div>
      <div style={{paddingTop: '10px'}}>
        <div><a href ='https://goo.gl/maps/fdUxnoosFgwowf9GA' target='_blank' rel='noopener noreferrer'>トンネル東京</a></div>
      </div>
      <div style={{width: '600px', maxWidth: '100%', paddingTop: '10px'}}>
        <ReactHlsPlayer
          playerRef={playerRef}
          src="https://keitamiyano.s3.ap-northeast-1.amazonaws.com/videos/touchdesignermeetup/transcoded/video.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div style={{paddingTop: '15px'}}/>
      <Divider/>
    </div>
  )
}

export default EventMovies