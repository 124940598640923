import React, { useState, useEffect } from 'react'
import { Note } from '../models/pitch';
import Divider from '@material-ui/core/Divider';
import { shuffle } from '../utils/arrayFunc';

type Props = {
  booting: boolean;
  siningLeft: boolean;
  setSiningLeft: React.Dispatch<React.SetStateAction<boolean>>;
  siningCenter: boolean;
  setSiningCenter: React.Dispatch<React.SetStateAction<boolean>>;
  siningRight: boolean;
  setSiningRight: React.Dispatch<React.SetStateAction<boolean>>;
  sineNoteLeft: Note;
  setSineNoteLeft: React.Dispatch<React.SetStateAction<Note>>;
  sineNoteCenter: Note;
  setSineNoteCenter: React.Dispatch<React.SetStateAction<Note>>;
  sineNoteRight: Note;
  setSineNoteRight: React.Dispatch<React.SetStateAction<Note>>;
  sineValue: number;
  setSineValue: React.Dispatch<React.SetStateAction<number>>;
}

const Profile: React.FC<Props> = ({ booting, siningLeft, setSiningLeft, siningCenter, setSiningCenter, siningRight, setSiningRight, sineNoteLeft, setSineNoteLeft, sineNoteCenter, setSineNoteCenter, sineNoteRight, setSineNoteRight, sineValue, setSineValue }) => {  
  const thStyle = {minWidth: '120px', width: '35%', fontWeight: 'inherit', paddingTop: '3px', paddingBottom: '3px', verticalAlign: 'top'};
  const tdStyle = {paddingTop: '3px', paddingBottom: '3px', verticalAlign: 'top'};
  const [imgId, setImgId] = useState(shuffle([4,5])[0]);
  return (
    <>
      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        {`環境音アーティスト Ambient Sound Artist`}
      </div>
      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        {`Keita Miyano`}
      </div>
      <div style={{paddingLeft: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33'}}>
        <img src={`/artistphoto/keitamiyano${imgId}.png`} style={{width: '200px', maxWidth: '40%'}}/>
      </div>
      <div style={{paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px'}}>
        <h1 style={{paddingBottom: '3px', fontSize: '12pt', color: '#1c1e33'}}>
          {`Vision`}
        </h1>
        <div style={{fontSize: '12pt'}}>
          {`すべての人との感覚の共有`}
        </div>
        <div style={{padding: '1px'}}/>
        <div style={{fontSize: '11pt'}}>
          {`Sharing the senses with all people.`}
        </div>
        <div style={{padding: '10px'}}/>
        <Divider/>
        <h1 style={{paddingBottom: '3px', fontSize: '12pt', color: '#1c1e33'}}>
          {`Solo Exhibition`}
        </h1>
        <table style={{width: '800px', maxWidth: '100%', fontSize: '10pt'}}>
          <tbody>
            <tr>
              <th align='left' style={thStyle}>2023.2</th>
              <td style={tdStyle}>
                初個展 「電​子​音​浴 -​中​動​態​の​環​境​音​-」
                @<a href ='https://goo.gl/maps/1U9ovvp2VQtv7dqa8' target='_blank' rel='noopener noreferrer'>FEI ART MUSEUM YOKOHAMA</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{padding: '10px'}}/>
        <Divider/>
        <h1 style={{paddingBottom: '3px', fontSize: '12pt', color: '#1c1e33'}}>
          {`History`}
        </h1>
        <table style={{width: '800px', maxWidth: '100%', fontSize: '10pt'}}>
          <tbody>
            <tr>
              <th align='left' style={thStyle}>2021.6 -</th>
              <td style={tdStyle}>async studio (CEO, founder)</td>
            </tr>
            <tr>
              <th align='left' style={thStyle}>2023.4 -</th>
              <td style={tdStyle}>栄光学園高等学校 (ゼミ講師)</td>
            </tr>
            <tr>
              <th align='left' style={thStyle}>2022.9 - 2022.12</th>
              <td style={tdStyle}>栄光学園中学校 (数学非常勤講師)</td>
            </tr>
            <tr>
              <th align='left' style={thStyle}>2019.4 - 2020.10</th>
              <td style={tdStyle}>estie (CTO, Co-founder)</td>
            </tr>
            <tr>
              <th align='left' style={thStyle}>2017.4 - 2019.3</th>
              <td style={tdStyle}>NTT DOCOMO (ML Engineer)</td>
            </tr>
            <tr>
              <th align='left' style={thStyle}>2017.3</th>
              <td style={tdStyle}>東京大学工学部卒業</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Profile
