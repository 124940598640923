import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from '../../utils/windowDimensions';
import { run } from '../../utils/runScript';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { MdMusicNote } from 'react-icons/md';
import { shuffle } from '../../utils/arrayFunc';
import { Note, NotePitch } from '../../models/pitch';
import { MdWaves } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';

const Turtledove = () => {
  const [sliderOn, setSliderOn] = useState(false);
  const { width } = useWindowDimensions();
  const [booting, setBooting] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [index, setIndex] = useState(0);
  const [nodeId, setNodeId] = useState(700);
  const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
  const [stopFlag, setStopFlag] = useState(true);
  const boot = () => {
    run(`boot();d_turtledove();`);
    setBooting(true);
  }
  const quit = () => {
    run(`quit();`);
    setBooting(false);
  }

  const updateNodeId = () => {
    if(nodeId<720){
      setNodeId(v => v+1);
    } else {
      setNodeId(700);
    }
  }

  useEffect(() => {
    if(!booting){return;}
    let interval = 630
    let waitTime = 1;
    switch (index) {
      case 0:
        waitTime = 1*interval;
        break;
      case 1:
        waitTime = 1.5*interval;
        break;
      case 2:
        waitTime = 0.5*interval;
        break;
      case 3:
        waitTime = 1*interval;
        break;
    }
    setTimeout(()=>{
      if (index<3) {
        setIndex(v=>v+1);
      } else {
        setIndex(0);
      }
    }, waitTime);
    if (index===3) {
      // 最後の声で、次の泣き声を出すか決める
      if (!stopFlag) {
        if (Math.random()<0.6) {
          setStopFlag(false);
        } else {
          setStopFlag(true);
        }
      } else {
        if (Math.random()<0.25) {
          setStopFlag(false);
        } else {
          setStopFlag(true);
        }
      }
    }
    // nodeId, amp, freq, sustain, slide, pitch1
    if (!stopFlag) {
      switch (index) {
        case 0:
          run(`s_turtledove(${nodeId}, ${1.5*1}, 349.228, ${0.5+0.08*Math.random()}, ${0.07+0.01*Math.random()}, 0.2);`);
          break;
        case 1:
          run(`s_turtledove(${nodeId+1}, ${1.5*1}, 349.228, ${0.58+0.04*Math.random()}, 0.1, 0.1);`);
          break;
        case 2:
          run(`s_turtledove(${nodeId+2}, ${1.5*1}, 440.000, 0.3, 0.15, 0.25);`);
          run(`s_turtledove(${nodeId+3}, ${1.5*(0.8+0.1*Math.random())}, 466.164, 0.3, 0.1, 0.1);`);
          break;
        case 3:
          run(`s_turtledove(${nodeId+4}, ${1.5*1}, 415.305, 0.55, 0.15, 0.3);`);
          run(`s_turtledove(${nodeId+5}, ${1.5*(0.8+0.1*Math.random())}, 466.164, 0.3, 0.1, 0.1);`);
          break;
      }
      updateNodeId();
    } else {
      if (index===0 && Math.random()<0.15) {
        run(`s_turtledove(${nodeId}, ${1.5*1}, 349.228, ${0.5+0.08*Math.random()}, ${0.07+0.01*Math.random()}, 0.2);`);
        updateNodeId();
      }
    }
    setPlaying(true);
  }, [index, booting])
  const scHigurashi = `
  ~~~supercollider
  SynthDef("turtledove", {
    arg amp=1, freq=410, sustain=1,
    slide=0.1, pitch1=0.1;
    var out=0, u, env;
    env = EnvGen.ar(
      Env.linen(0.04, 0, 1, 1, 5),
      timeScale: sustain,
      doneAction:2
    );
    freq = (
      freq*(1-slide)
      + EnvGen.ar(
        Env.linen(
          0, 0, 1, freq*slide, 5),
        timeScale:sustain
      )
    );
    u = 0;
    u = u + (
      SinOsc.ar(freq*1.05)
      * pitch1*0.1
    );
    u = u + (
      SinOsc.ar(freq*1)
      * 0.1
    );
    u = u!2;
    Out.ar(out, u*amp*env);
  }).add;
  ~~~
  `;
  useEffect(() => {
    try{
      new SharedArrayBuffer(1);
      setSharedArrayBufferEnable(true);
    }catch{
      setSharedArrayBufferEnable(false);
    }
  }, [])
  return (
    <div style={{padding: '10px'}}>
      <Grid container>
        <Button variant="outlined" startIcon={<PowerSettingsNewIcon />} onClick={()=>{boot()}} disabled={booting || !sharedArrayBufferEnable} size="medium">
          PLAY
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<PowerOffIcon />} onClick={()=>{quit()}} disabled={!booting || !sharedArrayBufferEnable} size="medium">
          QUIT
        </Button>
      </Grid>
      {/* <div style={{padding: '10px'}}/>
      <Grid container>
        <Button variant="outlined" startIcon={<MdMusicNote />} onClick={()=>{play()}} disabled={!booting || !sharedArrayBufferEnable || playing} size="medium">
          Sound
        </Button>
      </Grid> */}
      <div style={{padding: '10px'}}/>
      <div style={{whiteSpace: 'pre-wrap', width: '90vw', overflowX: 'scroll', overflowWrap: 'break-word', margin: '0 auto' }}>
        <ReactMarkdown children={scHigurashi}/>
      </div>
      {/* <Box sx={{ maxWidth: 'calc(100% - 100px)', width: '500px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MdMusicNote size={`2em`} color={booting ? 'inherit' : '#bdbdbd'}/>
            </Grid>
            <Grid item xs>
              <Slider value={sineValue} onChange={handleVolumeChange} disabled={!sliderOn}/>
            </Grid>
        </Grid>
      </Box> */}
    </div>
)
}

export default Turtledove