import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from '../../utils/windowDimensions';
import { run } from '../../utils/runScript';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { MdMusicNote } from 'react-icons/md';
import { shuffle } from '../../utils/arrayFunc';
import { Note, NotePitch } from '../../models/pitch';
import { MdWaves } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';

const Higurashi = () => {
  const [sliderOn, setSliderOn] = useState(false);
  const { width } = useWindowDimensions();
  const [booting, setBooting] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [index, setIndex] = useState(0);
  const [nodeId, setNodeId] = useState(800);
  const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
  const boot = () => {
    run(`boot();d_higurashi();`);
    setBooting(true);
  }
  const quit = () => {
    run(`quit();`);
    setBooting(false);
  }
  useEffect(() => {
    if(!booting){return;}
    setTimeout(()=>{
      setIndex(1-index);
    }, 700+(Math.random()*2000));
    if(Math.random()<0.3) {
      // nodeId, amp, slide, sustain
      run(`s_higurashi(${nodeId}, ${0.5+0.7*Math.random()}, ${-1+2*Math.random()}, ${8+4*Math.random()});`);
      if(nodeId<820){
        setNodeId(v => v+1);
      } else {
        setNodeId(800);
      }
    }
    setPlaying(true);
  }, [index, booting])
  const scHigurashi = `
  ~~~supercollider
  SynthDef("higurashi", {
    arg out, amp=1, sustain=1, slide=0;
    var freq, sig, env, parFreq,
    freqDiffRate, lpf,
    vibratoFreq=8, vibratoDepth=0.4;
    freq = 4186.009;
    freq = freq - (456.699*slide);
    freq = freq *(
      1+(
        (LFPar.ar(vibratoFreq)+1)
        *(vibratoDepth/50)
      )
    );
    freqDiffRate = 0.05;
    freq = freq*(1-freqDiffRate)
      + EnvGen.ar(
          Env.linen(0.07,0.3,0.5,freq*freqDiffRate,-2),
          timeScale:sustain
        );
    lpf = freq+Rand(1000, 3000);
    freq = freq*(
      1+((
        LFPar.ar(vibratoFreq)+1)
        *(vibratoDepth/50)
      ));
    sig = Saw.ar([freq,freq]);
    sig = sig+0.5*Saw.ar([(freq*2),(freq*2)]);
    sig = sig+0.2*Saw.ar([(freq/2),(freq/2)]);
    sig = sig + (PinkNoise.ar(1!2)/20);
    sig = LPF.ar(sig, lpf);
    parFreq = 1 + EnvGen.ar(
      Env.linen(0, 0, 0.6, 5, -2),
      timeScale:sustain
    );
    sig = sig*LFPar.kr(parFreq);
    env = EnvGen.ar(
      Env.linen(0, 0, 0.6, 1, -2),
      timeScale:sustain,doneAction:2
    );
    Out.ar(out, sig*amp*env);
  }).play;
  ~~~
  `;
  useEffect(() => {
    try{
      new SharedArrayBuffer(1);
      setSharedArrayBufferEnable(true);
    }catch{
      setSharedArrayBufferEnable(false);
    }
  }, [])
  return (
    <div style={{padding: '10px'}}>
      <Grid container>
        <Button variant="outlined" startIcon={<PowerSettingsNewIcon />} onClick={()=>{boot()}} disabled={booting || !sharedArrayBufferEnable} size="medium">
          PLAY
        </Button>
        <div style={{paddingLeft: '10px'}}/>
        <Button variant="outlined" startIcon={<PowerOffIcon />} onClick={()=>{quit()}} disabled={!booting || !sharedArrayBufferEnable} size="medium">
          QUIT
        </Button>
      </Grid>
      {/* <div style={{padding: '10px'}}/>
      <Grid container>
        <Button variant="outlined" startIcon={<MdMusicNote />} onClick={()=>{play()}} disabled={!booting || !sharedArrayBufferEnable || playing} size="medium">
          Sound
        </Button>
      </Grid> */}
      <div style={{padding: '10px'}}/>
      <div style={{whiteSpace: 'pre-wrap', width: '90vw', overflowX: 'scroll', overflowWrap: 'break-word', margin: '0 auto' }}>
        <ReactMarkdown children={scHigurashi}/>
      </div>
      {/* <Box sx={{ maxWidth: 'calc(100% - 100px)', width: '500px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MdMusicNote size={`2em`} color={booting ? 'inherit' : '#bdbdbd'}/>
            </Grid>
            <Grid item xs>
              <Slider value={sineValue} onChange={handleVolumeChange} disabled={!sliderOn}/>
            </Grid>
        </Grid>
      </Box> */}
    </div>
)
}

export default Higurashi