import React from 'react'
import Grid from '@material-ui/core/Grid';
import { BsGithub, BsInstagram, BsYoutube, BsTwitter } from 'react-icons/bs';
import { GrSoundcloud } from 'react-icons/gr';

const Links: React.FC = () => {
  return (
    <div style={{padding: '25px'}}>
      <Grid container spacing={2} >
        <Grid item>
          <a href='https://soundcloud.com/jtumafnqbjsb' target="_blank" rel="noopener noreferrer">
            <GrSoundcloud size={`1.5em`}/>
          </a>
        </Grid>
        <Grid item>
          <a href='https://github.com/mynkit' target="_blank" rel="noopener noreferrer">
            <BsGithub size={`1.5em`}/>
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.instagram.com/keitamiyano_/' target="_blank" rel="noopener noreferrer">
            <BsInstagram size={`1.5em`}/>
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.youtube.com/channel/UCgL1E7xKm1s_0WoItb-3CHA' target="_blank" rel="noopener noreferrer">
            <BsYoutube size={`1.5em`}/>
          </a>
        </Grid>
        <Grid item>
          <a href='https://twitter.com/keitamiyano_' target="_blank" rel="noopener noreferrer">
            <BsTwitter size={`1.5em`}/>
          </a>
        </Grid>
      </Grid>
    </div>
  )
}

export default Links