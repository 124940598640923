import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route } from "react-router-dom";
import { run } from './utils/runScript';
import Profile from './components/Profile';
import Works from './components/Works';
import News from './components/News';
import EventMovies from './components/EventMovies';
import Links from './components/Links';
import Contact from './components/Contact';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Home from './components/Home';
import Live from './components/Live';
import SoundBlocks from './components/SoundBlocks';
import { NotePitch, Note } from './models/pitch';
import { shuffle } from './utils/arrayFunc';
import Crow from './components/soloExhibition/Crow';
import Turtledove from './components/soloExhibition/Turtledove';
import Higurashi from './components/soloExhibition/Higurashi';
import { Live20230331 } from './components/Live20230331';
import {
  ReactP5Wrapper,
} from "react-p5-wrapper";
import sketch from './components/Sketch';
import { map } from './utils/mathFunc';

type Props = {
  targetPage: string;
}

const PortfolioSite: React.FC<Props> = ({ targetPage }) => {
  const [target, setTarget] = useState(targetPage);
  const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
  const [booting, setBooting] = useState(false);
  const [foresting, setForesting] = useState(false);
  const [raining, setRaining] = useState(false);
  const [rainValue, setRainValue] = useState(0);
  const [forestValue, setForestValue] = useState(0);
  const [bubbleValue, setBubbleValue] = useState(0);
  const [bubbleIndex, setBubbleIndex] = useState(0);
  const [bubbleDirection, setBubbleDirection] = useState(false);
  const [bubbleNodeId, setBubbleNodeId] = useState(2004);
  const [pourIndex, setPourIndex] = useState(0);
  const [pourNodeId, setPourNodeId] = useState(1004);
  const [siningLeft, setSiningLeft] = useState(false);
  const [siningCenter, setSiningCenter] = useState(false);
  const [siningRight, setSiningRight] = useState(false);
  const noteChoice = ['a3','d4','e4','fs4','a4','d5','e5','fs5'];
  const [sineNoteLeft, setSineNoteLeft] = useState<Note>(shuffle(noteChoice)[0]);
  const [sineNoteCenter, setSineNoteCenter] = useState<Note>(shuffle(noteChoice)[0]);
  const [sineNoteRight, setSineNoteRight] = useState<Note>(shuffle(noteChoice)[0]);
  const [sineValue, setSineValue] = useState(50);
  const [pourWater, setPourWater] = useState(false);
  const [pourWaterValue, setPourWaterValue] = useState(0);
  const [speed, setspeed] = useState(2);
  const [pause, setPause] = useState(false);
  const [clear, setClear] = useState(false);
  const masterVolume = 5;

  useEffect(() => {
    if(!booting){return}
    if(!foresting) {
      run(`s_forest()`);
      setForesting(true);
      setTimeout(()=>run(`c_forest(${masterVolume * forestValue / 100.})`), 10);
    }else{
      run(`c_forest(${masterVolume * forestValue / 100.})`);
    }
  }, [forestValue])

  useEffect(() => {
    if(!booting){return}
    if(!raining) {
      run(`s_rain()`);
      setRaining(true);
      setTimeout(()=>run(`c_rain(${masterVolume * rainValue / 100.})`), 10);
    }else{
      run(`c_rain(${masterVolume * rainValue / 100.})`);
    }
  }, [rainValue])

  useEffect(() => {
    if(!booting){return;}
    // setTimeout(()=>{
    //   setBubbleIndex(1-bubbleIndex);
    // }, (10+Math.random()*25));
    // let amp = masterVolume * bubbleValue / 100.;
    // if(amp<0.01){return;}
    // run(`s_bubble(${amp}, ${4000}, ${bubbleNodeId})`);
    // if(bubbleNodeId<2000) {
    //   setBubbleNodeId(bubbleNodeId+1);
    // }else{
    //   setBubbleNodeId(1004);
    // }
    let time = 5 + 300*((1 - bubbleValue/100)**3);
    time = time+Math.random()*time*(1 - bubbleValue/100);
    setTimeout(()=>{
      setBubbleIndex(1-bubbleIndex);
    }, (time));
    let amp = bubbleValue>0 ? masterVolume : 0;
    if(amp<0.01){return;}
    let rand = Math.random();
    let sustain = map(rand, 0, 1, 0.008, 0.07) * 1.1;
    let pan = map(Math.random(), 0, 1, -1, 1);
    let freq = map(Math.sqrt(rand), 0, 1, 391.995*16, 311.127*1.6);
    let accelerate = map(rand, 0, 1, 2, 4);
    let lpf = 5000;
    amp = amp * map(rand*rand, 0, 1, 0.06, 1);
    amp = amp * map(Math.random()*Math.random(), 0, 1, 0, 1);
    run(`s_sinewave(${amp*0.05}, ${sustain}, ${pan}, ${freq}, ${accelerate}, ${lpf}, ${bubbleNodeId})`);
    if(bubbleNodeId<3000) {
      setBubbleNodeId(bubbleNodeId+1);
    }else{
      setBubbleNodeId(2004);
    }
  }, [bubbleIndex, booting])

  useEffect(() => {
    if(!booting){return;}
    if(!pourWater){return;}
    let time = map(Math.random(), 0, 1, 0.5, 20)+10000*((1 - pourWaterValue/100)**5);
    setTimeout(()=>{
      if(pourWaterValue>=0.01){
        setPourIndex(1-pourIndex);
      } else {
        time *= 10;
      }
    }, (time));
    let amp = masterVolume;
    let rand = Math.random();
    let sustain = map(rand, 0, 1, 0.008, 0.07) * 1.2;
    let pan = 0;
    let freq = map(Math.sqrt(rand), 0, 1, 391.995*15, 311.127*0.7);
    let accelerate = map(rand, 0, 1, 2, 4.5);
    let lpf = 7000;
    amp = amp * map(rand*rand, 0, 1, 0.1, 1);
    amp = amp * map(Math.random()*Math.random(), 0, 1, 0, 1);
    run(`s_sinewave(${amp*0.07}, ${sustain}, ${pan}, ${freq}, ${accelerate}, ${lpf}, ${pourNodeId})`);
    if(pourNodeId<2000) {
      setPourNodeId(pourNodeId+1);
    }else{
      setPourNodeId(1004);
    }
  }, [pourIndex, booting, pourWater, pourWaterValue])

  useEffect(() => {
    if(!booting){return;}
    if(siningLeft) {
      run(`s_sine(${masterVolume * sineValue / 100.}, -1, ${NotePitch[sineNoteLeft]})`);
    }
  }, [siningLeft])

  useEffect(() => {
    if(!booting){return;}
    if(siningCenter) {
      run(`s_sine(${masterVolume * sineValue / 100.}, 0, ${NotePitch[sineNoteCenter]})`);
    }
  }, [siningCenter])

  useEffect(() => {
    if(!booting){return;}
    if(siningRight) {
      run(`s_sine(${masterVolume * sineValue / 100.}, 1, ${NotePitch[sineNoteRight]})`);
    }
  }, [siningRight])

  useEffect(() => {
    if(!booting){return;}
    if(siningLeft) {
      run(`c_sine(${masterVolume * sineValue / 100.}, -1, ${NotePitch[sineNoteLeft]})`);
    }
    if(siningCenter) {
      run(`c_sine(${masterVolume * sineValue / 100.}, 0, ${NotePitch[sineNoteCenter]})`);
    }
    if(siningRight) {
      run(`c_sine(${masterVolume * sineValue / 100.}, 1, ${NotePitch[sineNoteRight]})`);
    }
  }, [sineValue, sineNoteLeft, sineNoteCenter, sineNoteRight])

  return (
    <>
      <div style={{position: 'relative', width: '100%', zIndex: -1}}>
        <div style={{position: 'absolute'}}>
          <ReactP5Wrapper sketch={sketch} speed={speed} pause={pause} clear={target!=='home'} rainFall={rainValue/100} underWater={bubbleValue/100} pourWater={pourWater} setBubbleValue={setBubbleValue} setPourWaterValue={setPourWaterValue}></ReactP5Wrapper>
        </div>
      </div>
      <Sidebar target={target} setTarget={setTarget} setRainValue={setRainValue} setForestValue={setForestValue} setBubbleValue={setBubbleValue} setSineValue={setSineValue}/>
      <div style={{paddingTop: '3px', paddingLeft: '7px'}}>
        <Header/>
      </div>
      {target==='home' ? <Home
        sharedArrayBufferEnable={sharedArrayBufferEnable}
        setSharedArrayBufferEnable={setSharedArrayBufferEnable}
        booting={booting}
        setBooting={setBooting}
        foresting={foresting}
        setForesting={setForesting}
        raining={raining}
        setRaining={setRaining}
        rainValue={rainValue}
        setRainValue={setRainValue}
        forestValue={forestValue}
        setForestValue={setForestValue}
        bubbleValue={bubbleValue}
        setBubbleValue={setBubbleValue}
        bubbleDirection={bubbleDirection}
        setBubbleDirection={setBubbleDirection}
        pourWater={pourWater}
        setPourWater={setPourWater}
        pourWaterValue={pourWaterValue}
      />
      : target==='works' ? <Works/>
      : target==='news' ? <News/>
      : target==='event_movies' ? <EventMovies/>
      : target==='profile' ? (
        <Profile
          booting={booting}
          siningLeft={siningLeft}
          setSiningLeft={setSiningLeft}
          siningCenter={siningCenter}
          setSiningCenter={setSiningCenter}
          siningRight={siningRight}
          setSiningRight={setSiningRight}
          sineNoteLeft={sineNoteLeft}
          setSineNoteLeft={setSineNoteLeft}
          sineNoteCenter={sineNoteCenter}
          setSineNoteCenter={setSineNoteCenter}
          sineNoteRight={sineNoteRight}
          setSineNoteRight={setSineNoteRight}
          sineValue={sineValue}
          setSineValue={setSineValue}
        />)
      : target==='sound_blocks' ? (
        <SoundBlocks
          sharedArrayBufferEnable={sharedArrayBufferEnable}
          setSharedArrayBufferEnable={setSharedArrayBufferEnable}
          booting={booting}
          setBooting={setBooting}
        />)
      : target==='links' ? <Links/>
      : target==='contact' ? <Contact/>
      : <></>
      }
    </>
  )
}


const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Route exact path="/" render={() => <PortfolioSite targetPage='home' />} />
      <Route exact path="/sound_blocks" render={() => <PortfolioSite targetPage='sound_blocks' />} />
      <Route exact path="/live20230331" render={() => <Live20230331/>} />
      <Route exact path="/works" render={() => <PortfolioSite targetPage='works' />} />
      <Route exact path="/news" render={() => <PortfolioSite targetPage='news' />} />
      <Route exact path="/event_movies" render={() => <PortfolioSite targetPage='event_movies' />} />
      <Route exact path="/profile" render={() => <PortfolioSite targetPage='profile' />} />
      <Route exact path="/contact" render={() => <PortfolioSite targetPage='contact' />} />
      <Route exact path="/live" render={() => <Live />} />
      <Route exact path="/higurashi" render={() => <Higurashi />} />
      <Route exact path="/turtledove" render={() => <Turtledove />} />
      <Route exact path="/crow" render={() => <Crow />} />
    </BrowserRouter>
  )
}

export default App;
