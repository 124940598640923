export type Pitch = {
    a0: number;
    as0: number;
    bf0: number;
    b0: number;
    c1: number;
    cs1: number;
    df1: number;
    d1: number;
    ds1: number;
    ef1: number;
    e1: number;
    f1: number;
    fs1: number;
    gf1: number;
    g1: number;
    gs1: number;
    af1: number;
    a1: number;
    as1: number;
    bf1: number;
    b1: number;
    c2: number;
    cs2: number;
    df2: number;
    d2: number;
    ds2: number;
    ef2: number;
    e2: number;
    f2: number;
    fs2: number;
    gf2: number;
    g2: number;
    gs2: number;
    af2: number;
    a2: number;
    as2: number;
    bf2: number;
    b2: number;
    c3: number;
    cs3: number;
    df3: number;
    d3: number;
    ds3: number;
    ef3: number;
    e3: number;
    f3: number;
    fs3: number;
    gf3: number;
    g3: number;
    gs3: number;
    af3: number;
    a3: number;
    as3: number;
    bf3: number;
    b3: number;
    c4: number;
    cs4: number;
    df4: number;
    d4: number;
    ds4: number;
    ef4: number;
    e4: number;
    f4: number;
    fs4: number;
    gf4: number;
    g4: number;
    gs4: number;
    af4: number;
    a4: number;
    as4: number;
    bf4: number;
    b4: number;
    c5: number;
    cs5: number;
    df5: number;
    d5: number;
    ds5: number;
    ef5: number;
    e5: number;
    f5: number;
    fs5: number;
    gf5: number;
    g5: number;
    gs5: number;
    af5: number;
    a5: number;
    as5: number;
    bf5: number;
    b5: number;
    c6: number;
    cs6: number;
    df6: number;
    d6: number;
    ds6: number;
    ef6: number;
    e6: number;
    f6: number;
    fs6: number;
    gf6: number;
    g6: number;
    gs6: number;
    af6: number;
    a6: number;
    as6: number;
    bf6: number;
    b6: number;
    c7: number;
    cs7: number;
    df7: number;
    d7: number;
    ds7: number;
    ef7: number;
    e7: number;
    f7: number;
    fs7: number;
    gf7: number;
    g7: number;
    gs7: number;
    af7: number;
    a7: number;
    as7: number;
    bf7: number;
    b7: number;
    c8: number;
}

export type Note = keyof Pitch;

export const NotePitch: Pitch = {
    a0: 27.500,
    as0: 29.135,
    bf0: 29.135,
    b0: 30.868,
    c1: 32.703,
    cs1: 34.648,
    df1: 34.648,
    d1: 36.708,
    ds1: 38.891,
    ef1: 38.891,
    e1: 41.203,
    f1: 43.654,
    fs1: 46.249,
    gf1: 46.249,
    g1: 48.999,
    gs1: 51.913,
    af1: 51.913,
    a1: 55.000,
    as1: 58.270,
    bf1: 58.270,
    b1: 61.735,
    c2: 65.406,
    cs2: 69.296,
    df2: 69.296,
    d2: 73.416,
    ds2: 77.782,
    ef2: 77.782,
    e2: 82.407,
    f2: 87.307,
    fs2: 92.499,
    gf2: 92.499,
    g2: 97.999,
    gs2: 103.826,
    af2: 103.826,
    a2: 110.000,
    as2: 116.541,
    bf2: 116.541,
    b2: 123.471,
    c3: 130.813,
    cs3: 138.591,
    df3: 138.591,
    d3: 146.832,
    ds3: 155.563,
    ef3: 155.563,
    e3: 164.814,
    f3: 174.614,
    fs3: 184.997,
    gf3: 195.998,
    g3: 195.998,
    gs3: 207.652,
    af3: 207.652,
    a3: 220.000,
    as3: 233.082,
    bf3: 233.082,
    b3: 246.942,
    c4: 261.626,
    cs4: 277.183,
    df4: 277.183,
    d4: 293.665,
    ds4: 311.127,
    ef4: 311.127,
    e4: 329.628,
    f4: 349.228,
    fs4: 369.994,
    gf4: 369.994,
    g4: 391.995,
    gs4: 415.305,
    af4: 415.305,
    a4: 440.000,
    as4: 466.164,
    bf4: 466.164,
    b4: 493.883,
    c5: 523.251,
    cs5: 554.365,
    df5: 554.365,
    d5: 587.330,
    ds5: 622.254,
    ef5: 622.254,
    e5: 659.255,
    f5: 698.456,
    fs5: 739.989,
    gf5: 739.989,
    g5: 783.991,
    gs5: 830.609,
    af5: 830.609,
    a5: 880.000,
    as5: 932.328,
    bf5: 932.328,
    b5: 987.767,
    c6: 1046.502,
    cs6: 1108.731,
    df6: 1108.731,
    d6: 1174.659,
    ds6: 1244.508,
    ef6: 1244.508,
    e6: 1318.510,
    f6: 1396.913,
    fs6: 1479.978,
    gf6: 1479.978,
    g6: 1567.982,
    gs6: 1661.219,
    af6: 1661.219,
    a6: 1760.000,
    as6: 1864.655,
    bf6: 1864.655,
    b6: 1975.533,
    c7: 2093.005,
    cs7: 2217.461,
    df7: 2217.461,
    d7: 2349.318,
    ds7: 2489.016,
    ef7: 2489.016,
    e7: 2637.020,
    f7: 2793.826,
    fs7: 2959.955,
    gf7: 2959.955,
    g7: 3135.963,
    gs7: 3322.438,
    af7: 3322.438,
    a7: 3520.000,
    as7: 3729.310,
    bf7: 3729.310,
    b7: 3951.066,
    c8: 4186.009,
}