import React from 'react'

const Header: React.FC = () => {
  return (
    <>
      <img style={{width: '380px', maxWidth: '75%'}} src="/logo/logo_keitamiyano_rgb_1-2.png" alt="KEITA MIYANO" onContextMenu={(e)=>{e.preventDefault()}}/>
    </>
  )
}

export default Header